import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TicketShop from "./components/TicketShop";
import TeamListPage from "./components/TeamListPage";
import TournamentDraw from "./components/TournamentDraw";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<TicketShop></TicketShop>} />
        <Route path="/teams" element={<TeamListPage></TeamListPage>} />
        <Route path="/draw" element={<TournamentDraw></TournamentDraw>} />
        {/* Weitere Routen */}
      </Routes>
    </Router>
  );
};

export default App;
