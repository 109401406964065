import React, { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";

const LastTournamentSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const facts = [
    { label: "Gruppen", value: 4 },
    { label: "Teams", value: 16 },
    { label: "Highest Checkout", value: 129 },
    { label: "Legs gespielt", value: 138 },
    { label: "Liter Bier", value: 512 },
    { label: "Liter Apfelwein", value: 108 },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.3 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
    <section
      ref={sectionRef}
      className="flex flex-col lg:flex-row items-center lg:items-start justify-between bg-gray-900 p-8 text-center lg:text-left rounded-lg my-8 w-full max-w-6xl mx-auto"
    >
      <div className="container max-w-6xl mx-auto px-8 lg:px-12">
        <h2 className="text-3xl md:text-4xl font-bold mb-12 font-orbitron">
          About last year{" "}
        </h2>
        {/* Grid-Layout für die Kacheln */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {facts.map((fact, index) => (
            <div
              key={index}
              className="bg-gray-800 flex flex-col items-center justify-center rounded-lg shadow-lg p-4 h-32 sm:h-40 md:h-48"
            >
              {isVisible ? (
                <CountUp
                  start={0}
                  end={fact.value}
                  duration={2.5}
                  delay={index * 0.2}
                  className="text-3xl sm:text-4xl md:text-5xl font-bold"
                />
              ) : (
                <span className="text-3xl sm:text-4xl md:text-5xl font-bold">
                  0
                </span>
              )}
              <p className="text-sm sm:text-md md:text-lg mt-2 font-semibold">
                {fact.label}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default LastTournamentSection;
