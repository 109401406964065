import React from "react";

const PrivacyPolicy = ({ title, content, onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 overflow-y-auto">
      <div className="bg-gray-900 text-white w-full lg:w-[800px] h-full lg:h-auto p-6 overflow-y-auto relative rounded-lg mx-auto">
        <button
          onClick={onClose}
          className="text-gray-400 hover:text-gray-300 absolute top-4 right-4 text-2xl"
        >
          &times;
        </button>
        <h2 className="text-2xl font-semibold mb-4 text-left">{title}</h2>
        <div className="text-lg text-gray-300 text-left">{content}</div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
