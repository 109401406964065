import React, { useState } from "react";
import HeroSection from "./HeroSection";
import EventDescriptionSection from "./EventDescriptionSection";
import PlayerRegistrationSection from "./PlayerRegistrationSection";
import SponsorsSection from "./SponsorsSection";
import LastTournamentSection from "./LastTournamentSection";

const TicketShop = () => {
  const eventDate = new Date("2025-01-11T14:00:00");
  const registrationAvailableDate = new Date("2024-11-12T18:00:00");
  const ticketAvailableDate = new Date("2024-12-01T18:00:00");

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="bg-gray-800 text-gray-100 min-h-screen flex flex-col font-orbitron">
      <HeroSection
        eventStartDate={eventDate}
        scrollToSection={scrollToSection}
      />
      <LastTournamentSection />
      <EventDescriptionSection ticketAvailableDate={ticketAvailableDate} />
      <PlayerRegistrationSection
        registrationAvailableDate={registrationAvailableDate}
      />
      <SponsorsSection />

      {/* Impressum Link */}
      <div className="flex justify-center mt-2 mb-6">
        <a
          href="https://www.fcfreiergrund.de/impressum/"
          className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-full transition duration-300"
          target="_blank" // Öffnet das Impressum in einem neuen Tab
          rel="noopener noreferrer"
        >
          Impressum
        </a>
      </div>
    </div>
  );
};

export default TicketShop;
