import React, { useRef, useState, useEffect } from "react";
import { useOnScreen } from "../customHooks";
import { db, collection, addDoc, getDocs, where, query } from "../firebase";
import PrivacyPolicy from "./PrivacyPolicy";
import privacyPolicies from "../privacyPolicies.json"; // Import der JSON-Datei
import { serverTimestamp } from "firebase/firestore"; // Importiere serverTimestamp direkt

const PlayerRegistrationSection = ({ registrationAvailableDate }) => {
  const imageRef3 = useRef();
  const isVisible3 = useOnScreen(imageRef3);
  const [step, setStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegistrationAvailable, setIsRegistrationAvailable] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false); // Zustand für das Anzeigen der DSGVO
  const [isLoading, setIsLoading] = useState(false); // Ladezustand hinzufügen

  const [currentPolicy, setCurrentPolicy] = useState({
    title: "",
    content: "",
  });
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Formularzustände
  const [formState, setFormState] = useState({
    teamName: "",
    entryMusic: "",
    entryMusicLink: "",
    player1FirstName: "",
    player1LastName: "",
    player1Date: "",
    player1Email: "",
    player1ClubPlayer: false,
    player2FirstName: "",
    player2LastName: "",
    player2Date: "",
    player2Email: "",
    player2ClubPlayer: false,
    agreement: false,
    privacyAgreement: false,
    confirmSummary: false,
  });

  // Effekt, um Scrollen zu deaktivieren, wenn das Modal geöffnet ist
  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup-Effekt, um Overflow zurückzusetzen, falls der Effekt nicht ausgeführt wird
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen]);

  // Countdown-Berechnung für die Registrierung
  useEffect(() => {
    const calculateTimeLeft = () => {
      const now = new Date();
      const difference = registrationAvailableDate - now;

      if (difference > 0) {
        setTimeLeft({
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / (1000 * 60)) % 60),
          seconds: Math.floor((difference / 1000) % 60),
        });
      } else {
        setIsRegistrationAvailable(true);
      }
    };

    const timer = setInterval(calculateTimeLeft, 1000);
    return () => clearInterval(timer);
  }, [registrationAvailableDate]);

  const openPrivacyPolicy = (policyKey) => {
    const policy = privacyPolicies[policyKey] || privacyPolicies["default"];
    setCurrentPolicy({ title: policy.title, content: policy.content });
    setShowPrivacyPolicy(true);
  };

  const resetFormState = () => {
    setFormState({
      teamName: "",
      entryMusic: "",
      entryMusicLink: "",
      player1FirstName: "",
      player1LastName: "",
      player1Email: "",
      player1Date: "",
      player1ClubPlayer: false,
      player2FirstName: "",
      player2LastName: "",
      player2Date: "",
      player2Email: "",
      player2ClubPlayer: false,
      agreement: false,
      privacyAgreement: false,
      confirmSummary: false,
    });
  };

  // Funktionen zum Öffnen und Schließen des Modals
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    resetFormState();
    setIsModalOpen(false);
    setStep(1);
  };

  // Funktionen zur Navigation zwischen den Schritten
  const nextStep = () => {
    switch (step) {
      case 1:
        if (
          !formState.teamName ||
          !formState.entryMusic ||
          !formState.entryMusicLink
        ) {
          alert("Bitte fülle alle Felder aus.");
          return;
        }
        break;
      case 2:
        if (
          !formState.player1FirstName ||
          !formState.player1LastName ||
          !formState.player1Date ||
          !formState.player1Email
        ) {
          alert("Bitte fülle alle Felder für Spieler 1 aus.");
          return;
        }
        break;
      case 3:
        if (
          !formState.player2FirstName ||
          !formState.player2LastName ||
          !formState.player1Date ||
          !formState.player2Email
        ) {
          alert("Bitte fülle alle Felder für Spieler 2 aus.");
          return;
        }
        // Überprüfung, ob beide Spieler als Vereinsspieler markiert sind
        if (formState.player1ClubPlayer && formState.player2ClubPlayer) {
          alert(
            "Es kann nur ein Spieler pro Team als Vereinsspieler markiert werden."
          );
          return;
        }
        break;
      case 4:
        if (!formState.confirmSummary) {
          alert("Bitte bestätige die Angaben.");
          return;
        }
        break;
      default:
        break;
    }
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => setStep((prevStep) => prevStep - 1);

  // Funktion zur Verarbeitung der Eingaben
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState({
      ...formState,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Funktion für flexible Datumseingabe mit optionalen Punkten
  const handleDateChange = (e) => {
    let date = e.target.value;

    // Entfernt alle Nicht-Zahlen und Punkte
    date = date.replace(/[^0-9.]/g, "");

    // Fügt Punkte dynamisch hinzu, wenn ausreichend Zeichen vorhanden sind
    if (date.length > 2 && date[2] !== ".") {
      date = date.slice(0, 2) + "." + date.slice(2);
    }
    if (date.length > 5 && date[5] !== ".") {
      date = date.slice(0, 5) + "." + date.slice(5);
    }

    // Beschränkt das Datum auf maximal 10 Zeichen (TT.MM.JJJJ)
    if (date.length > 10) {
      date = date.slice(0, 10);
    }

    setFormState((prev) => ({
      ...prev,
      [e.target.name]: date,
    }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!formState.agreement || !formState.privacyAgreement) {
      alert("Bitte stimme den Anmeldebedingungen und Datenschutzerklärung zu.");
      return;
    }

    setIsLoading(true); // Ladeanimation starten

    // Prüfen auf vorhandenen Teamnamen oder E-Mail
    const querySnapshot = await getDocs(
      query(
        collection(db, "registrations"),
        where("teamName", "==", formState.teamName)
      )
    );

    if (!querySnapshot.empty) {
      setIsLoading(false);
      alert("Dieser Teamname ist bereits vergeben. Bitte wähle einen anderen.");
      return;
    }

    const emailQuerySnapshot1 = await getDocs(
      query(
        collection(db, "registrations"),
        where("player1.email", "==", formState.player1Email)
      )
    );
    if (!emailQuerySnapshot1.empty) {
      setIsLoading(false);
      alert(
        "Diese E-Mail-Adresse von Spieler 1 wird bereits verwendet. Achtung: Ein Spieler kann sich nur in einem Team anmelden!"
      );
      return;
    }

    const emailQuerySnapshot2 = await getDocs(
      query(
        collection(db, "registrations"),
        where("player2.email", "==", formState.player2Email)
      )
    );
    if (!emailQuerySnapshot2.empty) {
      setIsLoading(false);
      alert(
        "Diese E-Mail-Adresse von Spieler 2 wird bereits verwendet. Achtung: Ein Spieler kann sich nur in einem Team anmelden!"
      );
      return;
    }

    try {
      await addDoc(collection(db, "registrations"), {
        teamName: formState.teamName,
        entryMusic: formState.entryMusic,
        entryMusicLink: formState.entryMusicLink,
        player1: {
          firstName: formState.player1FirstName,
          lastName: formState.player1LastName,
          email: formState.player1Email,
          date: formState.player1Date,
          clubPlayer: formState.player1ClubPlayer,
        },
        player2: {
          firstName: formState.player2FirstName,
          lastName: formState.player2LastName,
          email: formState.player2Email,
          date: formState.player2Date,
          clubPlayer: formState.player2ClubPlayer,
        },
        agreement: formState.agreement,
        timestamp: serverTimestamp(),
      });
      alert(
        "Danke für deine Anmeldung!\nSobald die Anmeldephase abgeschlossen ist, erhälst du weitere Infos zum Anmeldeprozess"
      );
      closeModal();
    } catch (error) {
      console.error("Fehler beim Speichern der Anmeldung: ", error);
      alert("Fehler beim Speichern. Bitte versuche es erneut.");
    } finally {
      setIsLoading(false); // Ladeanimation stoppen
    }
  };

  return (
    <section
      id="playerRegistrationSection"
      className="flex flex-col lg:flex-row items-center justify-between bg-gray-900 p-8 text-center lg:text-left rounded-lg my-8 w-full max-w-6xl mx-auto"
    >
      <div className="w-full lg:w-1/2 mb-6 lg:mb-0 space-y-6">
        <h2 className="text-6xl font-semibold mb-8 lg:text-left text-center">
          Spieler
        </h2>
        <ul className="text-lg text-gray-300 space-y-2 text-left">
          <li>🎯 Begrenzte Team Anzahl</li>
          <li>🎯 2 Spieler pro Team</li>
          <li>🎯 Maximal 1 Vereinsspieler</li>
          <li>🎯 40,00€ Startgebühr pro Team</li>
          <li>🎯 Treffen eine Stunde vor Turnierbeginn (13:00 Uhr)</li>
          <li>
            🎯 Modus:
            <ul className="pl-4">
              <li>Single Out Gruppenphase / Achtelfinale</li>
              <li>Double Out ab Viertelfinale</li>
              <li className="font-bold">
                !! Double Out für Vereinsspieler ab Gruppenphase !!
              </li>
            </ul>
          </li>
          <li>🎯 Gruppenphase / Achtelfinale auf 6 Boards</li>
          <li>🎯 Hauptrunde auf der Westenergie Main Stage</li>
          <li>🎯 Pokal und Preisgeld für den Gewinner</li>
        </ul>

        {isRegistrationAvailable ? (
          <button
            onClick={openModal}
            className="bg-gray-400 text-gray-300 font-bold py-3 px-10 rounded-full mt-6 cursor-not-allowed"
            disabled={true}
          >
            Anmeldephase beendet
          </button>
        ) : (
          <div className="mt-4">
            <p className="text-lg">Anmeldung verfügbar in:</p>
            <p className="text-3xl font-bold mt-2">
              {timeLeft.days}t {timeLeft.hours}s {timeLeft.minutes}m{" "}
              {timeLeft.seconds}s
            </p>
          </div>
        )}
      </div>
      <div
        ref={imageRef3}
        className={`w-full lg:w-1/2 flex justify-center lg:justify-end ${
          isVisible3 ? "animate-slideInRight" : ""
        }`}
        style={{ position: "relative", zIndex: 10 }}
      >
        <img
          src="/images/_2500699.JPG"
          alt="Player registration"
          className="w-full lg:w-[500px] lg:h-[350px] object-cover rounded-lg shadow-lg"
        />
      </div>

      {/* Vollbild-Modal für mobile Ansicht */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 overflow-y-auto">
          <div className="bg-gray-900 text-white w-full lg:w-[800px] h-full lg:h-auto p-6 overflow-y-auto relative rounded-lg mx-auto">
            <button
              onClick={closeModal}
              className="text-gray-400 hover:text-gray-300 absolute top-4 right-4 text-2xl"
            >
              &times;
            </button>
            {step === 1 && (
              <form className="flex flex-col space-y-4">
                <h3 className="text-3xl font-semibold text-white mb-4">
                  Teaminfos
                </h3>
                <input
                  type="text"
                  name="teamName"
                  placeholder="Teamname"
                  value={formState.teamName}
                  onChange={handleInputChange}
                  className="px-6 py-4 rounded-lg border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-gray-500"
                />
                <input
                  type="text"
                  name="entryMusic"
                  placeholder="Einlaufmusik"
                  value={formState.entryMusic}
                  onChange={handleInputChange}
                  className="px-6 py-4 rounded-lg border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-gray-500"
                />
                <input
                  type="url"
                  name="entryMusicLink"
                  placeholder="Link zur Einlaufmusik"
                  value={formState.entryMusicLink}
                  onChange={handleInputChange}
                  className="px-6 py-4 rounded-lg border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-gray-500"
                />
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={nextStep}
                    className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-3 px-10 rounded-full transition duration-300"
                  >
                    Weiter
                  </button>
                </div>
              </form>
            )}
            {step === 2 && (
              <form className="flex flex-col space-y-4">
                <h3 className="text-3xl font-semibold text-white mb-4">
                  Spieler 1
                </h3>

                <input
                  type="text"
                  name="player1FirstName"
                  placeholder="Vorname"
                  value={formState.player1FirstName}
                  onChange={handleInputChange}
                  className="w-full px-6 py-4 rounded-lg border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-gray-500"
                />

                <input
                  type="text"
                  name="player1LastName"
                  placeholder="Name"
                  value={formState.player1LastName}
                  onChange={handleInputChange}
                  className="w-full px-6 py-4 rounded-lg border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-gray-500"
                />

                <input
                  type="text"
                  name="player1Date"
                  placeholder="Geburtsdatum (TT.MM.JJJJ)"
                  value={formState.player1Date}
                  onChange={handleDateChange}
                  maxLength="10" // Beschränkt auf 10 Zeichen im Format TT.MM.JJJJ
                  className="w-full px-6 py-4 rounded-lg border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-gray-500"
                />

                <input
                  type="email"
                  name="player1Email"
                  placeholder="E-Mail"
                  value={formState.player1Email}
                  onChange={handleInputChange}
                  className="w-full px-6 py-4 rounded-lg border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-gray-500"
                />

                <div className="flex items-center space-x-4">
                  <label className="text-lg text-white font-semibold">
                    Vereinsspieler:
                  </label>
                  <input
                    type="checkbox"
                    name="player1ClubPlayer"
                    checked={formState.player1ClubPlayer}
                    onChange={handleInputChange}
                    className="form-checkbox w-6 h-6 text-gray-700 rounded-md"
                  />
                </div>

                <div className="flex justify-between mt-6 space-x-4">
                  <button
                    type="button"
                    onClick={prevStep}
                    className="w-full sm:w-auto bg-gray-700 hover:bg-gray-600 text-white font-bold py-3 px-6 rounded-full transition duration-300"
                  >
                    Zurück
                  </button>

                  <button
                    type="button"
                    onClick={nextStep}
                    className="w-full sm:w-auto bg-gray-700 hover:bg-gray-600 text-white font-bold py-3 px-6 rounded-full transition duration-300"
                  >
                    Weiter
                  </button>
                </div>
              </form>
            )}
            {step === 3 && (
              <form className="flex flex-col space-y-4">
                <h3 className="text-3xl font-semibold text-white mb-4">
                  Spieler 2
                </h3>
                <input
                  type="text"
                  name="player2FirstName"
                  placeholder="Vorname"
                  value={formState.player2FirstName}
                  onChange={handleInputChange}
                  className="px-6 py-4 rounded-lg border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-gray-500"
                />
                <input
                  type="text"
                  name="player2LastName"
                  placeholder="Name"
                  value={formState.player2LastName}
                  onChange={handleInputChange}
                  className="px-6 py-4 rounded-lg border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-gray-500"
                />
                <input
                  type="text"
                  name="player2Date"
                  placeholder="Geburtsdatum (TT.MM.JJJJ)"
                  value={formState.player2Date}
                  onChange={handleDateChange}
                  maxLength="10" // Beschränkt auf 10 Zeichen im Format TT.MM.JJJJ
                  className="w-full px-6 py-4 rounded-lg border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-gray-500"
                />
                <input
                  type="email"
                  name="player2Email"
                  placeholder="E-Mail"
                  value={formState.player2Email}
                  onChange={handleInputChange}
                  className="px-6 py-4 rounded-lg border border-gray-600 bg-gray-800 text-white focus:outline-none focus:ring-2 focus:ring-gray-500"
                />
                <div className="flex items-center space-x-4 mt-2">
                  <label className="text-lg text-white font-semibold">
                    Vereinsspieler:
                  </label>
                  <input
                    type="checkbox"
                    name="player2ClubPlayer"
                    checked={formState.player2ClubPlayer}
                    onChange={handleInputChange}
                    className="form-checkbox w-6 h-6 text-gray-700 rounded-md"
                  />
                </div>
                <div className="flex justify-between">
                  <button
                    type="button"
                    onClick={prevStep}
                    className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-3 px-10 rounded-full transition duration-300"
                  >
                    Zurück
                  </button>
                  <button
                    type="button"
                    onClick={nextStep}
                    className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-3 px-10 rounded-full transition duration-300"
                  >
                    Weiter
                  </button>
                </div>
              </form>
            )}
            {step === 4 && (
              <form className="flex flex-col space-y-4">
                <h3 className="text-3xl font-semibold text-white mb-4">
                  Zusammenfassung
                </h3>
                <div className="text-left text-gray-300 space-y-2 p-4 border border-gray-700 rounded-lg bg-gray-800">
                  <p className="text-xl font-semibold mb-2">Team</p>
                  <p>
                    <strong>Teamname:</strong> {formState.teamName}
                  </p>
                  <p>
                    <strong>Einlaufmusik:</strong> {formState.entryMusic}
                  </p>
                  <p>
                    <strong>Link zur Einlaufmusik:</strong>{" "}
                    {formState.entryMusicLink}
                  </p>
                  <div className="border-t border-gray-600 mt-4 pt-4">
                    <p className="text-xl font-semibold mb-2">Spieler 1</p>
                    <p>
                      <strong>Vorname:</strong> {formState.player1FirstName}
                    </p>
                    <p>
                      <strong>Name:</strong> {formState.player1LastName}
                    </p>
                    <p>
                      <strong>Geburtsdatum:</strong> {formState.player1Date}
                    </p>
                    <p>
                      <strong>E-Mail:</strong> {formState.player1Email}
                    </p>
                    <p>
                      <strong>Vereinsspieler:</strong>{" "}
                      {formState.player1ClubPlayer ? "Ja" : "Nein"}
                    </p>
                  </div>
                  <div className="border-t border-gray-600 mt-4 pt-4">
                    <p className="text-xl font-semibold mb-2">Spieler 2</p>
                    <p>
                      <strong>Vorname:</strong> {formState.player2FirstName}
                    </p>
                    <p>
                      <strong>Name:</strong> {formState.player2LastName}
                    </p>
                    <p>
                      <strong>Geburtsdatum:</strong> {formState.player2Date}
                    </p>
                    <p>
                      <strong>E-Mail:</strong> {formState.player2Email}
                    </p>
                    <p>
                      <strong>Vereinsspieler:</strong>{" "}
                      {formState.player2ClubPlayer ? "Ja" : "Nein"}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4 mt-2">
                  <input
                    type="checkbox"
                    name="confirmSummary"
                    checked={formState.confirmSummary}
                    onChange={handleInputChange}
                    className="form-checkbox w-6 h-6 text-gray-700 rounded-md"
                  />
                  <label className="text-lg text-white font-semibold">
                    Ich bestätige, dass alle Angaben korrekt sind und der
                    Wahrheit entsprechen
                  </label>
                </div>
                <div className="flex justify-between mt-6">
                  <button
                    type="button"
                    onClick={prevStep}
                    className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-3 px-10 rounded-full transition duration-300"
                  >
                    Zurück
                  </button>
                  <button
                    type="button"
                    onClick={nextStep}
                    className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-3 px-10 rounded-full transition duration-300"
                  >
                    Weiter
                  </button>
                </div>
              </form>
            )}
            {step === 5 && (
              <form className="flex flex-col space-y-4 p-4 sm:p-6">
                <h3 className="text-3xl font-semibold text-white mb-4">
                  Bedingungen
                </h3>

                {/* Conditions Section */}
                <div className="flex flex-col space-y-4 mt-4">
                  {/* Checkbox for Registration Agreement */}
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      name="agreement"
                      checked={formState.agreement}
                      onChange={handleInputChange}
                      className="form-checkbox w-5 h-5 text-gray-700 rounded-md"
                    />
                    <label className="text-sm sm:text-lg text-white font-semibold text-left">
                      Ich bestätige die Anmeldebedingungen
                    </label>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        openPrivacyPolicy("registration");
                      }}
                      className="text-blue-400 underline text-xs sm:text-sm ml-2"
                    >
                      Anzeigen
                    </button>
                  </div>

                  {/* Checkbox for Privacy Policy Agreement */}
                  <div className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      name="privacyAgreement"
                      checked={formState.privacyAgreement}
                      onChange={handleInputChange}
                      className="form-checkbox w-5 h-5 text-gray-700 rounded-md"
                    />
                    <label className="text-sm sm:text-lg text-white font-semibold text-left">
                      Ich bestätige die Datenschutzerklärung
                    </label>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        openPrivacyPolicy("privacy");
                      }}
                      className="text-blue-400 underline text-xs sm:text-sm ml-2"
                    >
                      Anzeigen
                    </button>
                  </div>
                </div>

                {/* Button Section */}
                <div className="flex flex-col sm:flex-row justify-between space-y-4 sm:space-y-0 sm:space-x-4 mt">
                  <button
                    type="button"
                    onClick={prevStep}
                    className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 sm:py-3 px-8 sm:px-10 rounded-full transition duration-300 mt-6"
                  >
                    Zurück
                  </button>
                  <button
                    type="submit"
                    onClick={(e) => handleFormSubmit(e)}
                    disabled={isLoading}
                    className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 sm:py-3 px-8 sm:px-10 rounded-full transition duration-300 mt-6"
                  >
                    {isLoading ? (
                      <svg
                        className="animate-spin h-5 w-5 mr-2 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v4a4 4 0 00-4 4H4z"
                        ></path>
                      </svg>
                    ) : (
                      "Anmeldung abschließen"
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      )}
      {showPrivacyPolicy && (
        <PrivacyPolicy
          title={currentPolicy.title}
          content={currentPolicy.content}
          onClose={() => setShowPrivacyPolicy(false)}
        />
      )}
    </section>
  );
};

export default PlayerRegistrationSection;
