import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getDatabase, ref, onValue, update, set } from "firebase/database";
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
} from "firebase/auth";
import { realtimeDb } from "../firebase";

const TournamentDraw = () => {
  const [teams, setTeams] = useState({});
  const [groups, setGroups] = useState({});
  const [currentGroupIndex, setCurrentGroupIndex] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isViewerMode, setIsViewerMode] = useState(false);
  const [isAdminMode, setIsAdminMode] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authError, setAuthError] = useState("");

  const [searchParams] = useSearchParams();
  const auth = getAuth();

  useEffect(() => {
    if (searchParams.get("view") === "admin") {
      setIsAdminMode(true);
    } else {
      setIsViewerMode(true);
    }
  }, [searchParams]);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribeAuth();
  }, [auth]);

  useEffect(() => {
    const tournamentRef = ref(realtimeDb, "tournament");

    const unsubscribeRealtime = onValue(tournamentRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setTeams(data.teams || {});
        setGroups(data.groups || {});
        setCurrentGroupIndex(data.currentGroup || 0);
        setSelectedGroup(data.selectedGroup ?? null);
      }
    });

    return () => unsubscribeRealtime();
  }, []);

  const handleLogin = async () => {
    try {
      setAuthError("");
      await signInWithEmailAndPassword(auth, email, password);
    } catch (error) {
      setAuthError(
        "Fehler bei der Anmeldung. Bitte überprüfen Sie Ihre Daten."
      );
    }
  };

  const handleAssignTeam = async (teamId) => {
    if (isViewerMode || !isAuthenticated) return;

    const team = teams[teamId];
    if (!team) return;

    const updatedGroup = {
      ...groups[currentGroupIndex],
      [teamId]: team.teamName,
    };

    const updates = {};
    updates[`tournament/groups/${currentGroupIndex}`] = updatedGroup;
    updates[`tournament/teams/${teamId}/group`] = currentGroupIndex;
    updates[`tournament/currentGroup`] = (currentGroupIndex + 1) % 6;

    await update(ref(realtimeDb), updates);
  };

  const handleUndoLastAssignment = async () => {
    if (isViewerMode || !isAuthenticated) return;

    const previousGroupIndex = (currentGroupIndex - 1 + 6) % 6;
    const groupTeams = groups[previousGroupIndex];
    if (!groupTeams) return;

    const lastTeamId = Object.keys(groupTeams).pop();
    if (!lastTeamId) return;

    const updatedGroup = { ...groupTeams };
    delete updatedGroup[lastTeamId];

    const updates = {};
    updates[`tournament/groups/${previousGroupIndex}`] = updatedGroup;
    updates[`tournament/teams/${lastTeamId}/group`] = "null";
    updates[`tournament/currentGroup`] = previousGroupIndex;

    await update(ref(realtimeDb), updates);
  };

  const handleAdminSelectGroup = async (groupIndex) => {
    if (isViewerMode || !isAuthenticated) return;

    await set(ref(realtimeDb, "tournament/selectedGroup"), groupIndex);
  };

  const handleCloseDetailedView = async () => {
    if (isViewerMode || !isAuthenticated) return;

    await set(ref(realtimeDb, "tournament/selectedGroup"), null);
  };

  return (
    <section className="p-6 bg-black text-white min-h-screen flex flex-col items-center justify-center">
      {isAuthenticated || isViewerMode ? (
        <>
          <h1 className="text-4xl font-bold mb-8">
            {isViewerMode ? "Zuschaueransicht" : "Turnierauslosung"}
          </h1>
          <div className="w-full max-w-6xl">
            {selectedGroup !== null ? (
              <div className="border-2 rounded-lg p-6 bg-gray-800">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-semibold text-center">
                    Einzelansicht: Gruppe {selectedGroup + 1}
                  </h2>
                  {isAdminMode && (
                    <button
                      onClick={handleCloseDetailedView}
                      className="bg-red-600 text-white font-bold py-2 px-4 rounded-lg hover:bg-red-500"
                    >
                      Einzelansicht schließen
                    </button>
                  )}
                </div>
                <div className="flex flex-col gap-4">
                  {Array(4)
                    .fill(null)
                    .map((_, i) => {
                      const teamId = Object.keys(groups[selectedGroup] || [])[
                        i
                      ];
                      const teamName = teamId
                        ? groups[selectedGroup][teamId]
                        : null;
                      return (
                        <div
                          key={i}
                          className={`w-full h-24 p-4 flex items-center justify-center rounded-lg text-center border-2 ${
                            teamName
                              ? "bg-gray-600 text-white border-gray-500"
                              : "bg-gray-800 border-dashed border-gray-600"
                          }`}
                        >
                          {teamName || " "}
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {Array(6)
                  .fill()
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`border-2 rounded-lg p-4 cursor-pointer ${
                        index === currentGroupIndex
                          ? "border-green-500 bg-gray-800"
                          : "border-gray-500 bg-gray-700"
                      } ${
                        isViewerMode && index === selectedGroup
                          ? "border-yellow-500"
                          : ""
                      }`}
                      onClick={() =>
                        isAdminMode ? handleAdminSelectGroup(index) : null
                      }
                    >
                      <h2 className="text-lg font-semibold mb-4 text-center">
                        Gruppe {index + 1}
                      </h2>
                      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
                        {Array(4)
                          .fill(null)
                          .map((_, i) => {
                            const teamId = Object.keys(groups[index] || [])[i];
                            const teamName = teamId
                              ? groups[index][teamId]
                              : null;
                            return (
                              <div
                                key={i}
                                className={`w-full h-16 p-4 flex items-center justify-center rounded-lg text-center border-2 ${
                                  teamName
                                    ? "bg-gray-600 text-white border-gray-500"
                                    : "bg-gray-800 border-dashed border-gray-600"
                                }`}
                              >
                                {teamName || " "}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ))}
              </div>
            )}

            {isAdminMode && selectedGroup === null && (
              <>
                <div className="flex flex-wrap justify-center gap-2 mt-8">
                  {Object.entries(teams)
                    .filter(([_, team]) => team.group === "null")
                    .map(([teamId, team]) => (
                      <button
                        key={teamId}
                        onClick={() => handleAssignTeam(teamId)}
                        className="bg-blue-600 text-white font-bold py-1 px-2 rounded-md shadow-md hover:bg-blue-500 transition text-sm"
                      >
                        {team.teamName}
                      </button>
                    ))}
                </div>
                <button
                  className="bg-red-600 rounded px-12 py-4 m-4 text-center"
                  onClick={handleUndoLastAssignment}
                >
                  Undo
                </button>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-bold mb-4">Admin Login</h1>
          <form
            className="flex flex-col items-center gap-4 w-full max-w-sm"
            onSubmit={(e) => {
              e.preventDefault();
              handleLogin();
            }}
          >
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="E-Mail"
              className="p-2 w-full rounded border bg-gray-800 text-white"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Passwort"
              className="p-2 w-full rounded border bg-gray-800 text-white"
            />
            {authError && <p className="text-red-500">{authError}</p>}
            <button
              type="submit"
              className="py-2 px-4 bg-blue-600 text-white rounded-lg hover:bg-blue-500"
            >
              Anmelden
            </button>
          </form>
        </div>
      )}
    </section>
  );
};

export default TournamentDraw;
